<template>
  <div>
    <b-modal
      id="login"
      modal-class="main_menu__modal"
      dialog-class="main_menu__dialog"
      content-class="main_menu__content"
      body-class="main_menu__body"
      centered
      hide-footer
      hide-header
      scrollable
      size="md"
      #default="{ close }"
    >
      <b-container>
        <b-row align-h="end" align-v="start">
          <button @click="close">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                class="close__button"
                d="M19 5L5 19M5.00001 5L19 19"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </b-row>

        <b-row align-v="center" align-h="center">
          <div
            class="navbar__user_modal__wrapper"
            :class="user ? '' : 'outlined'"
          >
            <img
              v-if="user"
              class="navbar__user_modal__img"
              :src="require('@/assets/img/' + user.img)"
              alt=""
            />
            <svg
              v-else
              class="navbar__user_modal__svg"
              width="60%"
              height="60%"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                class="menu_login"
                d="M20 21V19C20 16.7909 18.2091 15 16 15H8C5.79086 15 4 16.7909 4 19V21M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </b-row>

        <b-row align-v="center" align-h="center">
          <p class="navbar__user_modal__label">Вход</p>
        </b-row>

        <b-row align-h="center" align-v="center">
          <div class="modal_line"></div>
        </b-row>

        <b-row align-h="center" align-v="center">
          <b-spinner v-if="loginButtonDisabled"></b-spinner>
          <p class="error_login" v-if="errorLogin">{{ errorLogin }}</p>
        </b-row>

        <div class="modal__center">
          <b-row class="modal__form_wrapper" align-h="center" align-v="center">
            <b-col cols="11">
              <b-form class="modal__form" @submit="login">
                <b-form-group label="Email или логин">
                  <b-form-input
                    id="email"
                    v-model="form.email"
                    type="text"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group label="Пароль">
                  <b-form-input
                    id="password"
                    type="password"
                    v-model="form.password"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-button
                  type="submit"
                  class="main__button modal_form__buttom"
                  :disabled="loginButtonDisabled"
                >
                  Вход
                </b-button>
              </b-form>
            </b-col>
          </b-row>

          <b-row align-v="center" align-h="center">
            <a class="modal__forgot_password">Забыли пароль?</a>
          </b-row>
        </div>

        <b-row align-v="center" align-h="center">
          <b-col cols="11">
            <b-row align-h="center">
              <div class="modal_line"></div>
              <button
                @click="to('Register')"
                class="outline__button modal_form__buttom"
                type="button"
              >
                Создать аккаунт
              </button>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      form: {
        email: null,
        password: null,
      },
      errorLogin: null,
      loginButtonDisabled: false,
    };
  },
  methods: {
    to(name) {
      if (this.$route.name != name) this.$router.push({ name: name });

      this.$bvModal.hide("login");
    },
    async login(e) {
      e.preventDefault();

      (this.errorLogin = null), (this.loginButtonDisabled = true);

      await this.$store
        .dispatch("login", this.form)
        .then(() => {
          this.loginButtonDisabled = false;
          this.$bvModal.hide("login");
          this.$router.go();
        })
        .catch(() => {
          this.errorLogin = "Неверный логин или пароль";
          this.loginButtonDisabled = false;
        });
    },
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
  },
};
</script>

<style lang="scss">
.error_login {
  color: $accent;
}
</style>
