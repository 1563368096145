import axios from "axios";

export default {
  state: {
    sprints: null,
  },
  mutations: {
    SET_SPRINTS(state, payload) {
      state.sprints = payload;
    },
  },
  actions: {
    async getSprints({ commit }) {
      try {
        let sprints = await axios.get("sprints").then((res) => {
          return res.data;
        });

        commit("SET_SPRINTS", sprints);
      } catch (e) {
        console.error(e);
      }
    },
  },
};
