import axios from "axios";

export default {
  state: {
    sprintGoals: null,
  },
  getters: {
    allSprintGoals: (state) => {
      return state.sprintGoals;
    },
  },
  mutations: {
    setSprintGoals(state, sprintGoals) {
      state.sprintGoals = sprintGoals;
    },
  },
  actions: {
    async getSprintGoals({ commit }, sprintId) {
      await axios.get(`sprint_goals/${sprintId}`).then((res) => {
        commit("setSprintGoals", res.data.data);
      });
    },
  },
};
