import axios from "axios";

export default {
  state: {
    sprintWeeks: null,
  },
  getters: {
    allSprintWeeks: (state) => {
      return state.sprintWeeks;
    },
    nowSprintWeek: (state) => {
      let nowDate = new Date();
      let nowSprintWeek = null;

      if (!state.sprintWeeks) {
        return nowSprintWeek;
      }

      for (const sprintWeek of state.sprintWeeks) {
        if (
          new Date(sprintWeek.start) <= nowDate &&
          new Date(sprintWeek.end) >= nowDate
        ) {
          nowSprintWeek = sprintWeek;
        }
      }

      return nowSprintWeek;
    },
  },
  mutations: {
    setSprintWeeks(state, weeks) {
      state.sprintWeeks = weeks;
    },
  },
  actions: {
    async getSprintWeeks({ commit }, sprintId) {
      await axios.get(`week_goals/${sprintId}`).then((res) => {
        commit("setSprintWeeks", res.data.weeks);
      });
    },
  },
};
