<template>
  <div id="app">
    <div class="app-bg"></div>

    <Navbar v-if="viewNav" />

    <div class="loading" v-if="loading">
      <!-- <b-spinner label="Loading" ></b-spinner> -->

      <div class="loader"></div>
    </div>

    <div class="main">
      <!-- <div class="app_padding_top" v-if="viewNav"></div> -->

      <router-view />

      <div class="app_padding_bottom" v-if="bottomPadding"></div>
    </div>

    <Footer />

    <Login />

    <MainMenu />

    <DynamicMenu />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import Login from "@/components/Login.vue";
import MainMenu from "@/components/MainMenu.vue";
import DynamicMenu from "@/components/DynamicMenu.vue";

export default {
  name: "App",
  components: {
    Navbar,
    Login,
    MainMenu,
    DynamicMenu,
    Footer,
  },
  data() {
    return {
      viewNav: true,
      bottomPadding: true,
    };
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },
  async mounted() {
    // Set theme
    document.body.setAttribute("data-theme", this.$store.state.user.theme);

    if (document.body.getAttribute("viewnav")) {
      this.viewNav = document.body.getAttribute("viewnav");
    }

    if (document.body.getAttribute("viewbpadding")) {
      this.bottomPadding = document.body.getAttribute("viewbpadding");
    }

    // Get user if have a token
    // if (localStorage.getItem("token")) {
    //   await this.$store.dispatch("getUser")
    // };
  },
};
</script>

<style lang="scss">
.app-bg {
  height: 100%;
  width: 100%;

  overflow: hidden;
  position: fixed;
  z-index: -1;

  opacity: 0.04;
  background-image: url("../src/assets/img/pattern-30.svg");
}

.main {
  min-height: 100vh;
}
.app_padding_top {
  height: 60px;
}
.app_padding_bottom {
  height: 20vh;
}
.loading {
  position: fixed;
  z-index: 10000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// .loader {
//   border: 16px solid #f3f3f3;
//   border-radius: 50%;
//   border-top: 16px solid #3498db;
//   width: 120px;
//   height: 120px;
//   -webkit-animation: spin 2s linear infinite; /* Safari */
//   animation: spin 2s linear infinite;
// }

.loader {
  height: 42px;
  width: 42px;

  background-image: url("./assets/img/logo.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
