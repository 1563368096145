import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/user",
    name: "User",
    component: () => import("@/views/User.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/password_reset",
    name: "PasswordReset",
    component: () => import("@/views/PasswordReset.vue"),
  },
  {
    path: "/new_password",
    name: "NewPassword",
    component: () => import("@/views/NewPassword.vue"),
  },
  {
    path: "/events",
    name: "Events",
    component: () => import("@/views/Events.vue"),
  },
  {
    path: "/check_registration",
    name: "CheckRegistration",
    component: () => import("@/views/CheckRegistration.vue"),
  },
  {
    path: "/terms_of_use",
    name: "TermsOfUse",
    component: () => import("@/views/TermsOfUse.vue"),
  },
  {
    path: "/tasks",
    name: "Tasks",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/bkonline/Tasks.vue"),
  },
  {
    path: "/task",
    name: "Task",
    component: () => import("@/views/bkonline/Task.vue"),
  },
  {
    path: "/free_week",
    name: "FreeWeek",
    component: () => import("@/views/bkonline/FreeWeek.vue"),
  },
  {
    path: "/users",
    name: "Users",
    component: () => import("@/views/Users.vue"),
  },
  {
    path: "/subscribe",
    name: "Subscribe",
    component: () => import("@/views/bkonline/Subscribe.vue"),
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("@/views/Register.vue"),
  },
  /**
   * Admin
   */
  {
    path: "/admin",
    name: "Admin",
    component: () => import("@/views/admin/Admin.vue"),
  },
  // Events editor
  {
    path: "/admin/events_editor",
    name: "EventsEditor",
    component: () => import("@/views/admin/EventsEditor.vue"),
  },
  // Events registrations
  {
    path: "/admin/events_registrations",
    name: "EventsRegistrations",
    component: () => import("@/views/admin/EventsRegistrations.vue"),
  },
  // Event
  {
    path: "/admin/registrations",
    name: "Registrations",
    component: () => import("@/views/admin/Registrations.vue"),
  },
  // Task editor
  {
    path: "/admin/tasks",
    name: "TasksEditor",
    component: () => import("@/views/admin/Tasks.vue"),
  },
  {
    path: "/admin/task_editor",
    name: "TaskEditor",
    component: () => import("@/views/admin/TaskEditor.vue"),
  },

  // LandingPage
  {
    path: "/state_about",
    name: "LandingPage",
    component: () => import("@/views/LandingPage.vue"),
  },

  // Sprint
  {
    path: "/sprint/:id",
    name: "Sprint",
    component: () => import("@/views/sprint/Sprint.vue"),
  },
  {
    path: "/sprints",
    name: "Sprints",
    component: () => import("@/views/sprint/Sprints.vue"),
  },

  {
    path: "/admin/completed_tasks",
    name: "CompletedTasks",
    component: () => import("@/views/admin/CompletedTasksView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0, behavior: "smooth" };
  },
});

export default router;
