<template>
  <div>
    <b-modal
      id="main_menu"
      modal-class="main_menu__modal"
      dialog-class="main_menu__dialog"
      content-class="main_menu__content"
      body-class="main_menu__body"
      centered
      hide-footer
      hide-header
      scrollable
      size="md"
      static
      #default="{ close }"
    >
      <b-container>
        <b-row align-h="end" align-v="start">
          <button @click="close">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                class="close__button"
                d="M19 5L5 19M5.00001 5L19 19"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </b-row>

        <b-row align-v="center" align-h="center">
          <div class="navbar__user_modal__wrapper outlined">
            <img
              v-if="user && user.img"
              class="navbar_user_photo"
              :src="$store.state.storageUrl + '/app/avatars/' + user.img"
              alt=""
            />
            <img
              v-else-if="user"
              class="navbar_user_photo"
              :src="$store.state.storageUrl + '/app/defaults/darkLogo.jpg'"
              alt=""
            />
            <svg
              v-else
              class="navbar__user_modal__svg"
              width="60%"
              height="60%"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                class="menu_login"
                d="M20 21V19C20 16.7909 18.2091 15 16 15H8C5.79086 15 4 16.7909 4 19V21M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </b-row>

        <b-row align-v="center" align-h="center">
          <button
            v-if="user"
            class="navbar__user_modal__label"
            @click="to(`User`, user.login ? user.login : null)"
          >
            <div class="menu_user_label_content">
              <p class="menu_user__info">
                {{ user.name }} {{ user.last_name }}
              </p>
              <svg
                width="9"
                height="14"
                viewBox="0 0 9 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  class="menu_item"
                  d="M0.992188 11.9995L5.99219 6.99951L0.992188 1.99951L1.99219 -0.000488281L8.99219 6.99951L1.99219 13.9995L0.992188 11.9995Z"
                  fill="#737373"
                />
              </svg>
            </div>
          </button>

          <button v-else @click="to('Login')" class="navbar__user_modal__label">
            <div class="menu_user_label_content">
              <p class="menu_user__info">Вход/регистрация</p>
              <svg
                width="9"
                height="14"
                viewBox="0 0 9 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  class="menu_item"
                  d="M0.992188 11.9995L5.99219 6.99951L0.992188 1.99951L1.99219 -0.000488281L8.99219 6.99951L1.99219 13.9995L0.992188 11.9995Z"
                  fill="#737373"
                />
              </svg>
            </div>
          </button>
        </b-row>

        <!-- User -->
        <b-row align-v="center" align-h="center" class="main_menu__block">
          <b-col cols="11">
            <b-row align-h="center">
              <button
                @click="to('Home')"
                :class="$route.path == '/' ? 'active' : ''"
                class="main_manu_button first"
              >
                Главная
              </button>
            </b-row>

            <b-row align-h="center">
              <button
                @click="to('Events')"
                :class="
                  $route.path == '/events' || $route.path == '/my_events'
                    ? 'active'
                    : ''
                "
                class="main_manu_button first"
              >
                Мероприятия
              </button>
            </b-row>

            <b-row align-h="center">
              <button
                @click="to('Tasks')"
                :class="$route.path == '/tasks' ? 'active' : ''"
                class="main_manu_button"
              >
                Состояние
              </button>
            </b-row>

            <b-row align-h="center">
              <button
                @click="to('Users')"
                :class="$route.path == '/users' ? 'active' : ''"
                class="main_manu_button"
              >
                Пользователи
              </button>
            </b-row>

            <b-row align-h="center">
              <button
                @click="to('Sprints')"
                :class="$route.path == '/sprints' ? 'active' : ''"
                class="main_manu_button"
              >
                Спринт
              </button>
            </b-row>

            <b-row align-h="center" v-if="user && user.role_id == 1">
              <button
                @click="to('Admin')"
                :class="$route.path == '/admin' ? 'active' : ''"
                class="main_manu_button"
              >
                Админка
              </button>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "MainMenu",
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    sprint_id() {
      return this.$store.state.user.sprint_id;
    },
  },
  methods: {
    to(pathName, login) {
      if (this.$route.name != pathName) {
        if (login) {
          this.$router.push({
            name: pathName,
            query: { user: this.user.login },
          });
        } else {
          this.$router.push({ name: pathName });
        }
      }

      this.$bvModal.hide("main_menu");
    },
  },
};
</script>

<style lang="scss">
.menu_item {
  stroke: none;
}
.menu_user__info {
  font-size: 16px;
  padding-right: 10px;
  margin: 0;
  font-weight: 500;
}
.menu_user_label_content {
  display: flex;
  align-items: center;
}
// menu block
.main_menu__block {
  padding-top: 30px;
}
.block_title {
  font-size: 20px;
  font-weight: 600;
}

.main_manu_button {
  width: 100%;
  padding: 10px 30px;
  margin: 5px;

  border-radius: $border;
  border: none;
  outline: none;

  background-color: rgba($color: $gray__medium_light, $alpha: 1);

  // text-transform: uppercase;
  text-align: left;
  font-weight: 500;
}

.main_manu_button.active {
  background-color: $accent;
  font-weight: 600;
}
</style>
