<template>
  <div class="home">
    <div class="padding_top"></div>

    <b-container>
      <b-row align-h="start" align-v="center" class="main_menu__header">
        <b-col cols="12" md="auto">
          <div class="logo_wrapper">
            <img
              class="logo_img"
              :src="require('@/assets/img/logo.png')"
              alt=""
            />
          </div>
        </b-col>

        <b-col cols="12" md="6">
          <b-row align-h="start" align-v="center">
            <b-col cols="auto">
              <p class="welcome_title">
                Добро пожаловать в БК
                <span class="accent_title">TEAMS</span><br />
              </p>

              <p class="welcome_message">
                Мы активно развиваемся и улучшаем нашу платформу<br />
                Спасибо, что Вы с нами!
              </p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row align-v="start" align-h="start">
        <b-col v-for="(link, index) in links" :key="index" cols="12" md="3" @click="to(link.pathName)">
          <div
            
            class="main_menu__wrapper"
            :class="$route.name == link.pathName ? 'active' : ''"
          >
            <img
              class="main_menu__img"
              v-if="link.img"
              :src="require(`@/assets/img/menu/${link.img}`)"
              :alt="link.imgAlt ? link.imgAlt : ''"
            />
            <div class="main_menu__img_filter"></div>
          </div>
          <div class="main_menu__text">
            <h3 class="main_menu__title" v-if="link.title">{{ link.title }}</h3>
            <p class="main_menu__description" v-if="link.description">
              {{ link.description }}
            </p>
          </div>
        </b-col>
      </b-row>

      <b-row align-h="center" align-v="end" class="main_menu__footer">
        <b-col cols="12" md="6">
          <p class="welcome_help_message">
            Узнать подробнее о БКteams 👉
            <a
              target="_blank"
              href="https://www.instagram.com/fedorjoy"
              class="accent"
              >@fedorjoy</a
            >
          </p>
        </b-col>

        <b-col cols="12" md="6">
          <p class="welcome_help_message">
            Сообщить об ошибке, пожелания, вопросы 👉
            <a href="tg://resolve?domain=elloston" class="accent">@elloston</a>
          </p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      links: [
        {
          title: "Состояние",
          pathName: "Tasks",
          admin: false,
          img: "condition.jpg",
          imgAlt: "БК teams состояние онлайн",
          description: "",
        },
        {
          title: "Мероприятия",
          pathName: "Events",
          admin: false,
          img: "events.jpg",
          imgAlt: "БК teams зарегистрироваться на живые мероприятия",
        },
        {
          title: "Пользователи",
          pathName: "Users",
          admin: false,
          img: "users.jpg",
          imgAlt: "БК teams пользователи",
        },
        {
          title: "Спринт",
          pathName: "Sprints",
          admin: false,
          img: "sprint.jpg",
          imgAlt: "БК teams спринт онлайн",
        },
      ],
    };
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
  },
  methods: {
    to(pathName) {
      if (this.$route.name != pathName) this.$router.push({ name: pathName });

      this.$bvModal.hide("main_menu");
    },
  },
  async mounted() {
    try {
      this.$store.commit("SET_LOADING", true);

      if (localStorage.getItem("token")) {
        await this.$store.dispatch("getUser");
      }

      this.$store.commit("SET_LOADING", false);
    } catch (e) {
      this.$store.commit("SET_LOADING", false);
      console.error(e);
    }
  },
};
</script>

<style lang="scss">
.logo_wrapper {
  height: 160px;
  width: 160px;
  position: relative;
  overflow: hidden;
}
.logo_img {
  height: 100%;
  width: 100%;
  position: absolute;
  object-fit: contain;
}

.welcome_title {
  text-align: start;
  font-weight: 500;
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.welcome_message {
  text-align: start;
  font-weight: 400;
  font-size: 18px;
  margin: 0;
}
.welcome_help_message {
  background-color: rgba($color: $gray__medium_light, $alpha: 1);
  border: 1px solid rgba($color: $white, $alpha: 0.1);
  border-radius: $border;
  padding: 15px 0;
  color: $light_gray__medium;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
}

.main_title {
  font-weight: 700;
  font-size: 32px;
  text-align: center;
}

a.accent:active,
a.accent:hover {
  color: $accent;
}

.accent_title {
  color: $accent;
  font-weight: 700;

  // background: -webkit-linear-gradient(315deg,#e40b0b 25%,#f7f7f7);
  // background-clip: text;
  // -webkit-text-fill-color: transparent;
}

.main_menu__wrapper {
  position: relative;

  overflow: hidden;
  height: 200px;

  // border-radius: $border;
  // border: 10px solid;
  border-color: rgba($color: $white, $alpha: 1);

  filter: saturate(100%);

  transition: all 800ms;
  cursor: pointer;
}
.main_menu__wrapper:hover {
  filter: saturate(0);

  transition: all 300ms;
}
.main_menu__wrapper.active {
  border-color: rgba($color: $accent, $alpha: 1);
}

.main_menu__img {
  position: absolute;
  z-index: 5;
  height: 100%;
  width: 100%;
  object-fit: cover;
  filter: brightness(120%);
  filter: saturate(140%);
}
.main_menu__img_filter {
  position: absolute;
  z-index: 10;
  height: 100%;
  width: 100%;
  object-fit: cover;

  background-color: rgba($color: #000000, $alpha: 0.5);
}
// text
.main_menu__text {
  background-color: $white;

  padding: 0 35px;
  padding-top: 10px;
  padding-bottom: 10px;

  font-style: italic;

  margin-bottom: 30px;
}
.main_menu__title {
  font-family: 'Comforter', cursive;
  // font-family: 'Bad Script', cursive;
  position: relative;
  z-index: 20;

  text-align: start;

  font-weight: 400;

  font-size: 30px;
  letter-spacing: 1px;

  margin: 0;

  color: rgba($color: $black, $alpha: 1);
}
.main_menu__description {
  position: relative;
  z-index: 20;

  font-weight: 500;
  font-size: 16px;
  color: $black;
  font-family: Andale Mono, monospace;
  line-height: normal;
}

.main_menu__footer {
  padding-top: 10vh;
}
.main_menu__header {
  padding-bottom: 15px;
}
</style>
