import axios from "axios";
import lodash from "lodash";
import moment from "moment";
moment.locale("ru");

export default {
  state: {
    events: null,
    myEvents: null,

    active: null,
    view: "all",
    editingEvent: null,
    typeEditing: "edit",
  },
  mutations: {
    SET_EVENTS(state, payload) {
      state.events = payload;
    },
    SET_MY_EVENTS(state, payload) {
      state.myEvents = payload;
    },
    SET_ACTIVE_EVENT(state, payload) {
      state.active = payload;
    },
    SET_EDITING_EVENT(state) {
      let event = lodash.cloneDeep(state.events[state.active]);
      state.editingEvent = event;

      let date = moment(String(state.editingEvent.date_time)).format(
        "YYYY-MM-DD"
      );
      state.editingEvent.date = date;

      let time = moment(String(state.editingEvent.date_time)).format("HH:mm");
      state.editingEvent.time = time;
    },
    SET_TYPE_EDITING(state, payload) {
      state.typeEditing = payload;
    },
    SET_VIEW(state, payload) {
      state.view = payload;
    },
  },
  actions: {
    async getEvents({ commit }) {
      let events = await axios.get("events").then((res) => {
        return res.data.data;
      });

      events = events.sort((a, b) => {
        return new Date(b.date_time) - new Date(a.date_time);
      });

      commit("SET_EVENTS", events);
    },
    async getMyEvents({ commit }) {
      const myEvents = await axios.get("my_registrations").then((res) => {
        return res.data;
      });

      commit("SET_MY_EVENTS", myEvents);
    },
  },
};
