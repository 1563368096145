import axios from "axios";
import moment from "moment";

export default {
  state: {
    theme: "dark",
    user: null,
    subscription: null,
  },
  mutations: {
    SET_USER(state, payload) {
      state.user = payload;
    },
    SET_SUBSCRIPTION(state, payload) {
      state.subscription = payload;
    },
    SET_SPRINT(state, payload) {
      state.sprint_id = payload.sprint_id;
    },
  },
  actions: {
    async login({ dispatch, rootState }, form) {
      await axios.get(rootState.csrfUrl);

      let token = await axios.post("login", {
        email: form.email,
        password: form.password,
        device_name: navigator.userAgent,
      });

      token = token.data;

      // Set token on local storage and axios
      localStorage.setItem("token", token);

      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

      // Get user
      await dispatch("getUser");
    },
    async getUser({ state, commit }) {
      const passport = await axios.get("passport").then((res) => {
        return res.data;
      });

      commit("SET_USER", passport.user);
      commit("SET_SUBSCRIPTION", passport.subscription);

      if (passport.sprint_id) {
        commit("SET_SPRINT", passport.sprint_id);
      }

      if (passport.subscription && passport.subscription.expiration_date) {
        if (
          moment(passport.subscription.expiration_date).format("YYYY-MM-DD") >=
          moment(new Date()).format("YYYY-MM-DD")
        ) {
          let randomMotivation = await axios
            .get("random_motivation")
            .then((res) => {
              return res.data;
            });

          let viewedMotivationToday = await axios
            .get("viewed_motivation_today")
            .then((res) => {
              return res.data;
            });

          state.user.motivationViewed =
            viewedMotivationToday.viewed_motivation_today;
          state.user.motivation = randomMotivation;
        }
      }
    },
  },
};
