import axios from "axios";

export default {
  state: {
    globalGoals: null,
  },
  getters: {
    allGlobalGoals: (state) => {
      return state.globalGoals;
    },
  },
  mutations: {
    setGlobalGoals(state, globalGoals) {
      state.globalGoals = globalGoals;
    },
  },
  actions: {
    async getGlobalGoals({ commit }) {
      await axios.get("global_goals").then((res) => {
        commit("setGlobalGoals", res.data.data);
      });
    },
  },
};

// this.today_plan = planingData.today_plan
