<template>
  <div>
    <b-modal
      id="dynamic_menu"
      modal-class="main_menu__modal"
      dialog-class="main_menu__dialog"
      content-class="main_menu__content"
      body-class="main_menu__body"
      centered
      hide-footer
      hide-header
      scrollable
      size="md"
      #default="{ close }"
    >
      <b-container v-if="$route.path == '/events'">
        <b-row align-h="end" align-v="start">
          <button @click="close">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                class="close__button"
                d="M19 5L5 19M5.00001 5L19 19"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </b-row>

        <b-row align-v="center" align-h="center" class="dynamic_menu__wrapper">
          <b-col cols="11">
            <b-row align-h="center">
              <button
                @click="setView('all')"
                :class="view == 'all' ? 'active' : ''"
                class="menu__button modal_form__buttom"
              >
                Все мероприятия
              </button>
            </b-row>

            <b-row align-h="center">
              <button
                @click="setView('my')"
                :class="view == 'my' ? 'active' : ''"
                class="menu__button modal_form__buttom"
              >
                Мои мероприятия
              </button>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "DynamicMenu",
  methods: {
    setView(type) {
      this.$store.commit("SET_VIEW", type);

      this.$bvModal.hide("dynamic_menu");
    },
  },
  computed: {
    view() {
      return this.$store.state.events.view;
    },
  },
};
</script>

<style lang="scss">
.dynamic_menu__wrapper {
  height: 90vh;
}
</style>
