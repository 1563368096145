import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueYandexMetrika from "vue-yandex-metrika";

// Axios
import axios from "axios";

axios.defaults.baseURL = `${store.state.apiUrl}`;
axios.defaults.withCredentials = true;
// axios.defaults.headers['Access-Control-Allow-Origin'] = '*';

if (localStorage.getItem("token"))
  axios.defaults.headers.common["Authorization"] =
    "Bearer " + localStorage.getItem("token");

Vue.config.productionTip = true;

// Swiper
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
import Swiper from "swiper/bundle";

Vue.use(VueAwesomeSwiper, {
  Swiper,
});

Vue.use(VueYandexMetrika, {
  id: 68703688,
  router: router,
  env: process.env.NODE_ENV,
  options: {
    webvisor: true,
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
