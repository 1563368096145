<template>
  <div>
    <div
      v-if="$route.name == 'FreeWeek' || $route.name == 'Subscribe'"
      @click="to('LandingPage')"
      class="additional_logo"
    >
      <div class="additional_info__block">
        <p class="additional_info__text">Подробнее</p>

        <svg
          class="additional_info__logo"
          width="18"
          height="18"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            class="additional_info__logo__path"
            d="M12 22C6.47967 21.9939 2.00606 17.5203 2 12V11.8C2.10993 6.30452 6.63459 1.92794 12.1307 2.00087C17.6268 2.07379 22.0337 6.56887 21.9978 12.0653C21.9619 17.5618 17.4966 21.9989 12 22ZM11.984 20H12C16.4167 19.9956 19.9942 16.4127 19.992 11.996C19.9898 7.57928 16.4087 3.99999 11.992 3.99999C7.57528 3.99999 3.99421 7.57928 3.992 11.996C3.98979 16.4127 7.56729 19.9956 11.984 20ZM13 18H11V16H13V18ZM13 15H11C10.9684 13.6977 11.6461 12.4808 12.77 11.822C13.43 11.316 14 10.88 14 9.99999C14 8.89542 13.1046 7.99999 12 7.99999C10.8954 7.99999 10 8.89542 10 9.99999H8V9.90999C8.01608 8.48093 8.79333 7.16899 10.039 6.46839C11.2846 5.76778 12.8094 5.78493 14.039 6.51339C15.2685 7.24184 16.0161 8.57093 16 9.99999C15.9284 11.079 15.3497 12.0602 14.44 12.645C13.6177 13.1612 13.0847 14.0328 13 15Z"
            fill="#2E3A59"
          />
        </svg>
      </div>
    </div>

    <div class="nav">
      <b-container>
        <b-row align-h="between" align-v="center" class="navbar_wrapper">
          <b-col cols="auto">
            <div class="navbar__burger_wrapper" v-if="$route.path == '/events'">
              <button v-b-modal.dynamic_menu>
                <div class="navbar__burger_line"></div>
                <div class="navbar__burger_line"></div>
                <div class="navbar__burger_line"></div>
              </button>
            </div>

            <div @click="to('Home')" class="nav__logo_wrapper" v-else>
              <img
                class="nav__logo_img"
                :src="require('@/assets/img/logo.png')"
                alt=""
              />
            </div>
          </b-col>

          <b-col cols="auto">
            <div class="navbar__user" @click="showModal('main_menu')">
              <button class="navbar__user_wrapper outlined">
                <img
                  v-if="user && user.img"
                  class="navbar_user_photo"
                  :src="$store.state.storageUrl + '/app/avatars/' + user.img"
                />

                <img
                  v-else-if="user"
                  class="navbar_user_photo"
                  :src="$store.state.storageUrl + '/app/defaults/darkLogo.jpg'"
                />
                <svg
                  v-else
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    class="menu_login"
                    d="M20 21V19C20 16.7909 18.2091 15 16 15H8C5.79086 15 4 16.7909 4 19V21M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>

              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  class="navbar_menu__link"
                  d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  class="navbar_menu__link"
                  d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  class="navbar_menu__link"
                  d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      form: {
        email: null,
        password: null,
      },
      menu: [
        {
          title: "Главная",
          link: "Home",
        },
        {
          title: "Мероприятия",
          link: "Events",
        },
        {
          title: "БКonline",
          link: "Tasks",
          secondLink: "Task",
        },
        {
          title: "Пользователи",
          link: "Users",
        },
      ],
    };
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
  },
  methods: {
    to(pathName) {
      if (this.$route.name != pathName) {
        this.$router.push({ name: pathName });
      }
    },
    showModal(id) {
      this.$bvModal.show(id);
    },
  },
};
</script>

<style lang="scss">
// main
.nav {
  position: fixed;
  top: 0;
  right: 0;

  z-index: 1000;

  background-color: rgba($black, 0.5);
  backdrop-filter: blur(4px);
  border-color: $gray;

  width: 100%;
}
.navbar_wrapper {
  height: 60px;
}
.navbar_padding {
  height: 120px;
}

// links
.navbar_menu__link {
  margin: 5px;
  padding: 5px;
  color: $light_gray__medium;
  transition: all 200ms;
  font-size: 16px;
}
.navbar_menu__link:hover {
  color: $white;
  transition: all 200ms;
}
.navbar_menu__link.active {
  color: $accent;
}

// logo
.nav__logo_wrapper {
  height: 30px;
  width: 42px;
  position: relative;
  overflow: hidden;
}
.nav__logo_wrapper:hover {
  cursor: pointer;
}
.nav__logo_img {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.menu_login {
  stroke: $light_gray__dark;
}
.outlined {
  border: solid 1px;
}

// burger
.navbar__burger_wrapper {
  width: 40px;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar__burger_line {
  width: 30px;
  height: 2px;

  margin-top: 6px;
  margin-bottom: 6px;
}

// user
.navbar__user {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.navbar__user_wrapper {
  height: 42px;
  width: 42px;

  position: relative;
  overflow: hidden;

  border-radius: 100%;
}
.navbar__user:focus,
.navbar__user:focus-visible {
  outline: none;
}
.navbar_user_photo {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;

  object-fit: cover;
  height: 100%;
  width: 100%;
}

.navbar__user_modal__wrapper {
  position: relative;
  overflow: hidden;

  height: 110px;
  width: 110px;

  border-radius: 100%;

  margin-bottom: 5px;
}
.navbar__user_modal__img {
  height: 100%;
  width: 100%;

  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;

  position: absolute;
  object-fit: cover;
}
.navbar__user_modal__svg {
  height: 50%;
  width: 50%;

  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;

  position: absolute;
  object-fit: cover;
}
.navbar__user_modal__label {
  font-size: 24px;
  font-weight: 600;

  margin-bottom: 0;
}
.modal__forgot_password {
  font-size: 14px;
  text-decoration: underline;
  font-weight: 500;
  cursor: pointer;
}
.modal_line {
  width: 70%;
  height: 2px;

  margin-top: 30px;
  margin-bottom: 30px;
}
.modal__center {
  padding-top: 15px;
  padding-bottom: 15px;
}

.additional_logo {
  position: fixed;
  z-index: 20;
  top: 30px;
  left: 30px;

  height: 40px;
  width: 40px;

  cursor: pointer;
  padding: 5px;
}

.additional_info__text {
  font-size: 14px;
  margin: 0;
  color: rgba($color: $white, $alpha: 0.7);
}

.additional_info__block {
  display: flex;
  width: 50vw;
  align-items: center;
}
.additional_info__logo {
  margin-left: 5px;
}

.additional_info__logo__path {
  fill: rgba($color: $white, $alpha: 0.7);
}

.navbar_menu__link {
  stroke: rgba($color: $white, $alpha: 0.6);
}
</style>
