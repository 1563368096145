import Vue from "vue";
import Vuex from "vuex";
import user from "@/store/modules/user.js";
import events from "@/store/modules/events.js";
import bkonline from "@/store/modules/bkonline.js";
import sprintWeeks from "@/store/modules/sprintWeeks.js";
import globalGoal from "@/store/modules/globalGoal.js";
import sprintGoals from "@/store/modules/sprintGoals.js";
import sprint from "@/store/modules/sprint.js";

Vue.use(Vuex);

const laravelUrl = "http://online.bkteams.ru/laravel/";

export default new Vuex.Store({
  state: {
    // Vue
    appUrl: "https://online.bkteams.ru",
    loading: false,

    // Laravel

    apiUrl: `${laravelUrl}public/api/`,
    storageUrl: `${laravelUrl}storage`,
    csrfUrl: `${laravelUrl}public/sanctum/csrf-cookie`,
  },
  getters: {
    loading: (state) => {
      return state.loading;
    },
  },
  mutations: {
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
  },
  actions: {},
  modules: {
    user,
    events,
    bkonline,
    sprintWeeks,
    globalGoal,
    sprintGoals,
    sprint,
  },
});
