import axios from "axios";
import moment from "moment";

export default {
  state: {
    tasks: null,
    subscriptionTypes: null,
    activeTask: Boolean,
    selectedLevel: localStorage.getItem("selectedLevel")
      ? localStorage.getItem("selectedLevel")
      : 0,
  },
  mutations: {
    SET_TASKS(state, payload) {
      state.tasks = payload;
    },
    SET_STATUS_ACTIVE_TASK(state, payload) {
      state.activeTask = payload;
    },
    SET_SUBSCRIPTION_TYPES(state, payload) {
      state.subscriptionTypes = payload;
    },
  },
  actions: {
    async getTasks({ commit }) {
      await axios.get("tasks").then((res) => {
        commit("SET_TASKS", res.data);

        let date1 = moment(res.data.last_date_completing).format("YYYY-MM-DD");
        let date2 = moment(new Date()).format("YYYY-MM-DD");

        var activeTask = date1 < date2;
        commit("SET_STATUS_ACTIVE_TASK", activeTask);
      });
    },
    async editTask({ commit }, data) {
      await axios
        .post("editing_task/" + data.id, { answers: data.answers })
        .then((res) => {
          return res;
          // set new all tasks - add backend
        });
    },
    // Subscribe
    async getSubscriptionTypes({ commit }) {
      await axios.get("subscriptions_types").then((res) => {
        commit("SET_SUBSCRIPTION_TYPES", res.data);
      });
    },

    //Admin
    async getAdminTasks({ commit, state }) {
      await axios.get(`/admin/tasks/${state.selectedLevel}`).then((res) => {
        commit("SET_TASKS", res.data.data);
      });
    },
  },
};
